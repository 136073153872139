import React, { useEffect, useReducer, useContext, useState } from "react";
import axios from "axios";
import { Store } from "../Store";
import API from "../components/API";
import StickyCategories from "../components/StickyCategories";
import { Helmet } from "react-helmet";
import ShowcaseProdFix from "../components/ShowcaseProdFix";

const api_url = API();

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return { ...state, loading: true };
    case "FETCH_SUCCESS":
      return { ...state, products: action.payload, loading: false };
    case "FETCH_FAIL":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
function HomeScreen() {
  const [{ loading, error, products }, dispatch] = useReducer(reducer, {
    products: [],
    loading: true,
    error: "",
  });
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(`${api_url}/api/db/category`);
      setCategories(data);
      dispatch({ type: "FETCH_REQUEST" });
      try {
        const resultP = await axios.get(`${api_url}/api/db/products`);
        dispatch({ type: "FETCH_SUCCESS", payload: resultP.data });
      } catch (err) {
        dispatch({ type: "FETCH_FAIL", payload: err.message });
      }
    };
    fetchData();
  }, []);
    //This will scroll to the top of the page
    function scrollTop() {
      document.body.scrollTo({ top: 0});
    }
    useEffect(() => {
      scrollTop();
    }
    , [])

  const stickCats = StickyCategories();

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { cart } = state;
  const addToCartHandler = async () => {
    const existItem = cart.cartItems.find(
      (x) => x.product_id === products.product_id
    );
    const quantity = existItem ? existItem.quantity + 1 : 1;
    ctxDispatch({ type: "CART_ADD_ITEM", payload: { ...products, quantity } });
  };

  return (
        <div className="grid-container">
          <Helmet>
            <title>
              Dice Habit | Dice Showcase
            </title>
          </Helmet>
          {ShowcaseProdFix(products, categories)}          
        </div>
  );
}

export default HomeScreen;
