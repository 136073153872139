import { useContext, useEffect, useState } from "react";
import { Store } from "../Store";
import Button from "react-bootstrap/Button";
import { Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import API from "../components/API";
import { Helmet } from "react-helmet";
import Table from "react-bootstrap/Table";
import cartTable from "../components/CartTable";
import emailjs from "emailjs-com";
import PopupClear from "../components/Popup-Clear";

const api_url = API();
const categoryQuantityPriceBreakEnabled = true;
const categoryDollarPriceBreakEnabled = true;
const dollarTarget = 4000;
let discountApplied = false

function orderText(cart) {
    let readable = [];
    for (let i = 0; i < cart.length; i++) {
        let tmp = [];
        tmp.push(cart[i].sku);
        tmp.push(cart[i].item_name);
        tmp.push(cart[i].quantity);
        tmp.sort(function (a, b) {
            return a[0] - b[0];
        });
        readable.push(tmp);
    }
    return JSON.stringify(readable, null, 4);
}

function getCustomerInfo() {
    let address = document.getElementsByName("address")[0].value;
    let city = document.getElementsByName("city")[0].value;
    let state = document.getElementsByName("state")[0].value;
    let zip = document.getElementsByName("zip_code")[0].value;

    let final_address = address + ", " + city + ", " + state + " " + zip;
    const customerInfo = [
        {
            Contact_Name: document.getElementsByName("contact_name")[0].value,
            Store_Name: document.getElementsByName("store_name")[0].value,
            Email: document.getElementsByName("email")[0].value,
            Phone_Number: document.getElementsByName("phone_number")[0].value,
            Address: final_address,
        },
    ];
    return customerInfo;
}

function phoneNumberFormatter(e) {
    e.target.value = e.target.value.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

function getDistinctCategories(cartItems) {
    let categories = [];
    for (let i = 0; i < cartItems.length; i++) {
        if (!categories.includes(cartItems[i].category)) {
            categories.push(cartItems[i].category);
        }
    }
    return categories;
}

function categoryCount(cartItems) {
    let categories = getDistinctCategories(cartItems);
    let counts = [];
    for (let i = 0; i < categories.length; i++) {
        let count = 0;
        for (let j = 0; j < cartItems.length; j++) {
            if (categories[i] === cartItems[j].category) {
                count += parseInt(cartItems[j].quantity);
            }
        }
        counts.push(count);
    }
    return counts;
}

async function getItemsInCategory(category) {
    const { data } = await axios.get(`${api_url}/api/db/products`);
    let items = [];
    for (let i = 0; i < data.length; i++) {
        if (data[i].category === category && !getDiscountExclusions().includes(data[i].sku)) {
            items.push(data[i]);
        }
    }
    return items;
}

function getDiscountExclusions() {
    return ["DAD677", "DAD676", "DAD675", "DAD553", "DAD552", "DAD842", "DAD841", "DAD065", "DAD066", "DAD359", "DAD360"];
}

function getSubtotal(cartItems) {
    var subtotal = 0;
    for (let i = 0; i < cartItems.length; i++) {
        subtotal += cartItems[i].retail_price * cartItems[i].discount * cartItems[i].quantity;
    }
    return subtotal;
}

function getNumItems(cartItems) {
    var numItems = 0;
    for (let i = 0; i < cartItems.length; i++) {
        numItems += parseInt(cartItems[i].quantity);
    }
    return numItems;
}
function setDiscounts(cartItems, dollarTarget) {
    const totalDiscountedPrice = cartItems.reduce((total, curentItem) => total + curentItem.retail_price * 0.5 * curentItem.quantity, 0);

    if (categoryDollarPriceBreakEnabled) {
        if (totalDiscountedPrice >= dollarTarget) {
            discountApplied = true;
            applyDiscountToCartItems(cartItems, 0.5);
        } else if (discountApplied) {
            discountApplied = false;
            applyDiscountToCartItems(cartItems, 0.6);
        }
    }
}

function applyDiscountToCartItems(cartItems, discountAmount) {
    let discountExclusions = getDiscountExclusions();
    cartItems.forEach((item) => {
        if (!discountExclusions.includes(item.sku)) {
            item.discount = discountAmount;
        }
    });
}


export default function CartScreen() {
    const navigate = useNavigate();
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const { cart: { cartItems } } = state;
    const [clearPopup, setClearPopup] = useState(false);

    useEffect(() => {
        setDiscounts(cartItems, dollarTarget);
    }, [cartItems]);

    async function sendEmail(e) {
        document.getElementsByName("invoice")[0].value = orderText(cartItems);
        document.getElementsByName("subtotal")[0].value = getSubtotal(cartItems).toFixed(2);
        e.preventDefault();

        emailjs.sendForm("gmail", "template_efy4yfj", e.target, "JbAH_8vx7P3bdnZsP")
            .then(
                (result) => {
                    ctxDispatch({ type: "CART_CLEAR" });
                    localStorage.removeItem("cartItems");
                    navigate("/OrderSubmission");
                },
                (error) => {
                    alert("Something went wrong. Our developers will take a look at the log file and fix this ASAP.");
                }
            );
    }

    function subtotal() {
        return cartItems.reduce((a, c) => a + c.retail_price * c.discount * c.quantity, 0).toFixed(2);
    }

    if (cartItems.length === 0) {
        return (
            <div>
                <Helmet>
                    <title>Dice Habit | Shopping Cart</title>
                </Helmet>
                <div style={{ paddingTop: "6vh" }}></div>
                <div className="container-fluid">
                    <div className="container-CartScreen-Checkout">
                        <h3 style={{ "font-weight": "bolder" }}>Enter Customer Information</h3>
                        <form onSubmit={sendEmail} style={{ width: "100%" }}>
                            <div className="row">
                                <div className="col">
                                    <div className="inputBox">
                                        <input tabIndex={100} type="text" name="store_name" placeholder="Store Name" required />
                                    </div>
                                    <div className="inputBox">
                                        <input tabIndex={104} type="text" name="address" placeholder="Street Address" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="inputBox">
                                        <input tabIndex={101} type="text" name="contact_name" placeholder="Contact Name" required />
                                    </div>
                                    <div className="inputBox">
                                        <input tabIndex={105} type="text" name="city" placeholder="City" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="inputBox">
                                        <input tabIndex={102} type="email" name="email" placeholder="Email" required />
                                    </div>

                                    <div className="flex">
                                        <div className="inputBox">
                                            <input tabIndex={106} type="text" name="state" placeholder="State" />
                                        </div>
                                        <div className="inputBox">
                                            <input tabIndex={107} type="text" name="zip_code" placeholder="Zip Code" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="inputBox">
                                        <input
                                            tabIndex={103}
                                            onChange={phoneNumberFormatter}
                                            type="telNO"
                                            id="phone-number"
                                            name="phone_number"
                                            size="20"
                                            minLength={9}
                                            maxLength={14}
                                            placeholder="Phone Number"
                                            required
                                            style={{ "margin-bottom": "5px" }}
                                        />
                                    </div>
                                    <div className="inputBox">
                                        <textarea name="invoice" style={{ display: "none" }} defaultValue={orderText(cartItems)}></textarea>
                                        <textarea name="subtotal" style={{ display: "none" }} defaultValue={subtotal()}></textarea>
                                    </div>
                                    <div className="inputBox">
                                        <Button tabIndex={108} as="input" className="submit-btn" type="submit" value="Submit Order" disabled />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="container-CartScreen-Checkout">
                        <h4 className="Cart-Subtotal row center anchor" style={{ "font-weight": "bolder", "font-size": "26px" }} id="subtotal">
                            Subtotal ({cartItems.reduce((a, c) => a + parseInt(c.quantity), 0)} items):
                            ${cartItems.reduce((a, c) => a + c.retail_price * c.discount * c.quantity, 0).toFixed(2)}
                        </h4>
                    </div>
                    <Alert style={{ "text-align": "center" }}>
                        Cart is empty.{" "}
                        <Link tabIndex={-1} to="/BulkOrder">
                            Go Shopping
                        </Link>
                    </Alert>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <Helmet>
                    <title>Dice Habit | Shopping Cart</title>
                </Helmet>
                <div style={{ paddingTop: "6vh" }}></div>
                <div className="container-fluid">
                    <div className="container-CartScreen-Checkout">
                        <h3 style={{ "font-weight": "bolder" }}>Enter Customer Information</h3>
                        <form onSubmit={sendEmail} style={{ width: "100%" }}>
                            <div className="row">
                                <div className="col">
                                    <div className="inputBox">
                                        <input tabIndex={100} type="text" name="store_name" placeholder="Store Name" required />
                                    </div>
                                    <div className="inputBox">
                                        <input tabIndex={104} type="text" name="address" placeholder="Street Address" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="inputBox">
                                        <input tabIndex={101} type="text" name="contact_name" placeholder="Contact Name" required />
                                    </div>
                                    <div className="inputBox">
                                        <input tabIndex={105} type="text" name="city" placeholder="City" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="inputBox">
                                        <input tabIndex={102} type="email" name="email" placeholder="Email" required />
                                    </div>

                                    <div className="flex">
                                        <div className="inputBox">
                                            <input tabIndex={106} type="text" name="state" placeholder="State" />
                                        </div>
                                        <div className="inputBox">
                                            <input tabIndex={107} type="text" name="zip_code" placeholder="Zip Code" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="inputBox">
                                        <input
                                            tabIndex={103}
                                            onChange={phoneNumberFormatter}
                                            type="telNO"
                                            id="phone-number"
                                            name="phone_number"
                                            size="20"
                                            minLength={9}
                                            maxLength={14}
                                            placeholder="Phone Number"
                                            required
                                            style={{ "margin-bottom": "5px" }}
                                        />
                                    </div>
                                    <div className="inputBox">
                                        <textarea name="invoice" style={{ display: "none" }} defaultValue={orderText(cartItems)}></textarea>
                                        <textarea name="subtotal" style={{ display: "none" }} defaultValue={subtotal()}></textarea>
                                    </div>
                                    <div className="inputBox">
                                        <Button tabIndex={108} as="input" className="submit-btn" type="submit" value="Submit Order" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="container-CartScreen-Checkout">
                        <div className="Cart-Subtotal vertical-align">
                            <h4 className="row center" style={{ "font-weight": "bolder", "font-size": "26px" }} id="subtotal">
                                Subtotal ({cartItems.reduce((a, c) => a + parseInt(c.quantity), 0)} items):
                                ${cartItems.reduce((a, c) => a + c.retail_price * c.discount * c.quantity, 0).toFixed(2)}
                            </h4>
                            <ul className="right top colum-list">
                                <Link to="/BulkOrder">
                                    <text className="width-fit">Add/Modify Order</text>
                                </Link>

                                <text onClick={setClearPopup}>Clear All</text>
                            </ul>
                        </div>
                        <PopupClear trigger={clearPopup} setTrigger={setClearPopup}></PopupClear>
                    </div>
                    <Table striped bordered>
                        <tbody>
                            <tr className="Category-Product-table-subHeader">
                                {["Image", "SKU", "Product Name", "Retail", "Unit Cost", "Quantity", "Total", ""].map((header, index) => (
                                    <th key={index} style={{ backgroundColor: "#669" }}>{header}</th>
                                ))}
                            </tr>
                            {cartItems.sort((a, b) => a.sku.localeCompare(b.sku)).map((item, index) => cartTable(item))}
                            {setDiscounts(cartItems, dollarTarget)}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}