import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

function OrderSubmission(){
    return(
        <div className="container">
            <h4 className="Category-Header row center anchor">
                Thank You!
            </h4>
            <div className="center alt-expo"style={ {
                        'padding': '5% 0',
                        'textAlign': 'center',
                        }}>
                <span>
                    Your order has been successfully submitted.
                </span>
                <div>
                    <img src="\images\DiceHabitLogoNoBorder.svg" alt="oops"/>
                </div>
                <span>
                    Keep an eye on your email for any potential updates or follow-ups regarding your order.
                </span>
            </div>
            <div>
            <Alert style={{"text-align": "center"}}>
                Cart is empty. <Link to="/BulkOrder">Go Shopping</Link>
            </Alert>
            </div>
        </div>
    );
}

export default OrderSubmission;