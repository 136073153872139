import React, { useEffect, useReducer, useContext, useState, Fragment } from "react";
import axios from "axios";
import { Store } from "../Store";
import ProductTable from "../components/ProductTableOLD";
import Table from "react-bootstrap/Table";
import API from "../components/API";
import { Button } from 'react-bootstrap';
import colorMap from "../components/ColorMap";
import CategoryMap from "../components/CategoryMap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./bulkOrderScreenStyle.css"
import { lightenColor } from "../utils/ColorUtils";


const api_url = API();
export default function BulkOrderScreen() {
	function scrollTop() {
		document.body.scrollTo({ top: 0, behavior: "smooth" });
	}

	function addToCartHandlerCategory(event, cat) {
		let bulkAmount = Math.round(event.target.value);
		if (bulkAmount > 0) {
			const prod = products.filter((products) => products.category === cat);
			for (let i = 0; i < prod.length; i++) {
				let temp = document.getElementById(prod[i].product_id);
				temp.value = bulkAmount;
				const existItem = cart.cartItems.find((x) => x.product_id === products.product_id);
				let quantity = existItem ? 0 : Math.round(document.getElementById(prod[i].product_id).value);
				if (quantity !== 0) {
					quantity = Math.round(quantity);
				}
				ctxDispatch({
					type: "CART_ADD_ITEM",
					payload: { ...prod[i], quantity },
				});
			}
		} else if (bulkAmount == 0) {
			const prod = products.filter((products) => products.category === cat);
			for (let i = 0; i < prod.length; i++) {
				let temp = document.getElementById(prod[i].product_id);
				temp.value = bulkAmount;
				const existItem = cart.cartItems.find((x) => x.product_id === products.product_id);
				const quantity = existItem ? 0 : Math.round(document.getElementById(prod[i].product_id).value);
				ctxDispatch({
					type: "CART_REMOVE_ITEM",
					payload: { ...prod[i], quantity },
				});
			}
		} else {
			alert("Please use a positive number!");
		}
	}
	function ClearCart() {
		const inputs = document.querySelectorAll("input");
		inputs.forEach((input) => {
			input.value = "";
		});
		ctxDispatch({ type: "CART_CLEAR" });
		localStorage.removeItem("cartItems");
	}

	const reducer = (state, action) => {
		switch (action.type) {
			case "FETCH_REQUEST":
				return { ...state, loading: true };
			case "FETCH_SUCCESS":
				return { ...state, products: action.payload, loading: false };
			case "FETCH_FAIL":
				return { ...state, loading: false, error: action.payload };
			default:
				return state;
		}
	};
	const [{ loading, error, products }, dispatch] = useReducer(reducer, {
		products: [],
		loading: true,
		error: "",
	});
	const [categories, setCategories] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			const { data } = await axios.get(`${api_url}/api/db/category`);
			setCategories(data);
			dispatch({ type: "FETCH_REQUEST" });
			try {
				const resultP = await axios.get(`${api_url}/api/db/products`);
				dispatch({ type: "FETCH_SUCCESS", payload: resultP.data });
			} catch (err) {
				dispatch({ type: "FETCH_FAIL", payload: err.message });
			}
		};
		fetchData();
	}, []);
	useEffect(() => {
		scrollTop();
	}, []);
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart } = state;
	const reverseSort = (element) => {
		const newProducts = products.reverse();
		dispatch({ type: "FETCH_SUCCESS", payload: newProducts });
		setTimeout(() => {
			document.getElementById(element).scrollIntoView();
		}, 1);
	}
	const [search, setSearch] = useState("");

	return (
		<div className="bulk-order-screen">
			<Helmet>
				<title>Dice Habit | Order Page</title>
			</Helmet>
			{orderingControls()}
			<div className="table-container">
				<table>
					<tbody>
						{CategoryMap
							.filter((category) => {
								return products.filter((product) => {
									return product.category === category && (product.sku.toLowerCase().includes(search.toLowerCase()) || product.item_name.toLowerCase().includes(search.toLowerCase()));
								}).length > 0;
							})
							.map((categories) => (
								<Fragment key={categories}>
									<td
										id={`${categories}`}
										colSpan={9}
										className={'subTable-Category-scrollPadding'}
									>
										<th
											className="subTable-category-title"
											style={{
												backgroundColor: colorMap[categories],
												color: "white"
											}}>
											<div className="subTable-category-title-name">{categories}</div>
											{categoryQuantityPriceBreakBubble(categories)}
											<div className="lightning-bulkOrder-container">
												<div className="lightning-bulkOrder">I would like to order
													<br></br>
													{
														<input
															id={colorMap[categories]}
															name={"Bulk" + colorMap[categories]}
															type="number"
															pattern=" 0+\.[0-9]*[1-9][0-9]*$"
															step="1"
															min={0}
															placeholder="qty"
															className=""
															onChange={(event) => addToCartHandlerCategory(event, categories)}></input>
													}
													<br></br>
													of every set in this category</div>
											</div>
										</th>
									</td>
									<tr className="table-legend" id={categories + "1"} style={{ backgroundColor: lightenColor(colorMap[categories], 30) }}>
										<th>#</th>
										<th></th>
										<th onClick={() => reverseSort(categories)}>SKU { }
											{products[0].sku <= products[1].sku ? <i className="fa fa-arrow-up"></i> : <i className="fa fa-arrow-down"></i>}
										</th>
										<th>Product Name</th>
										<th>Image</th>
										<th>Retail</th>
										<th>Unit Cost</th>
										<th>Quantity</th>
										<th>Line Total</th>
									</tr>
									{renderProductTable(products, categories, search)}
								</Fragment>
							))}
					</tbody>
				</table>
			</div>
		</div>
	);

	function orderingControls() {
		return <div className="stickyControls">
			<div className="checkOut-buttons-BulkOrder">
				<input
					type="search"
					id="search-input"
					placeholder="Search SKU or Name"
					onChange={(e) => setSearch(e.target.value)}
				/>
				<div>
					{categoryDollarPriceBreakBubble(cart)}
					<div className="parentContainer">
						<Button
							variant="flat"
							onClick={ClearCart}
							style={{ 'background-color': '#222222' }}>
							Clear All
						</Button>
						<Link to="/cart">
							<Button
								variant="flat"
								onClick={scrollTop}
								style={{ 'background-color': '#3EB489' }}>
								Checkout
							</Button></Link>
						<Button
							variant="flat"
							onClick={scrollTop}
							style={{ 'background-color': '#0f2e93' }}
						>
							{getSubtotal(cart) <= 4000 ? (
								<Link to="/cart">SubTotal ${getSubtotal(cart)}</Link>
							) : (
								(4000 - cart.cartItems.reduce((a, c) => a + c.retail_price * 0.5 * c.quantity, 0)).toFixed(2) >= 0 ? (
									<div>
										<i className="fa fa-arrow-up"></i> <i className="fa fa-arrow-up"></i> See Above  <i className="fa fa-arrow-up"></i> <i className="fa fa-arrow-up"></i>
									</div>
								) : <Link to="/cart">SubTotal ${getSubtotal(cart)}</Link>
							)}
						</Button>
					</div>
				</div>
			</div>
		</div>
	}
}
const categoryQuantityPriceBreakEnabled = false;
const categoryDollarPriceBreakEnabled = true;
let discountApplied = false;

function renderProductTable(products, categories, search) {
	if (!discountApplied) {
		products.map((products) => {
			products.discount = 0.6;
		})
		return addProductsToTable();
	}
	else {
		products.map((products) => {
			if (getDiscountExclusions().includes(products.sku) === false) {
				products.discount = 0.5;
			}
		})
		return addProductsToTable();
	}

	function addProductsToTable() {
		return products
			.filter((products) => products.category === categories)
			.filter((products) => {
				return products.sku.toLowerCase().includes(search.toLowerCase()) || products.item_name.toLowerCase().includes(search.toLowerCase());
			})
			.sort()
			.reverse()
			.map((products) => (
				<ProductTable key={products.product_id} products={products}></ProductTable>
			));
	}
}

function categoryDollarPriceBreakBubble(cart) {
	if (categoryDollarPriceBreakEnabled) {
		const dollarTarget = 4000;
		setDiscount(cart, dollarTarget);
		return (
			<div className="categoryDollarPriceBreakContainer">
				<p className="categoryDollarPriceBreakDeal">50% OFF Deal</p>
				<div className="categoryDollarPriceBreakLabel">
					<div>
						{cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) === 0 ? <span>Buy $<b>{dollarTarget}</b> to get <b>50% OFF MSRP</b></span> :
							cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) < dollarTarget ? <span>Add $<b>{remainderOfDollarTarget(dollarTarget)}</b> to get <b>50% OFF MSRP</b></span> :
								<span><text style={{ color: "#FFD700" }}>Yay! </text>You'll get <b>50% OFF MSRP</b> for your Order!</span>
						}
					</div>
					<text className="categoryDollarPriceBreakDealExclustion">*excluding a few 40% off only items*</text>
				</div>
			</div>);
	} else {
		return
	}

	function remainderOfDollarTarget(dollarTarget) {
		return ((dollarTarget - cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0)).toFixed(2)).toString();
	}
}

function categoryQuantityPriceBreakBubble(categories) {
	if (categoryQuantityPriceBreakEnabled) {
		return <p className="Category-sales">Buy
			{categories === "Metal & Enamel Dice Set"
				|| categories === "(D20) Metal & Enamel Dice"
				|| categories === "Gemstone Dice Set"
				|| categories === "Glass Dice Set"
				|| categories === "(D6) Metal & Enamel Dice Set" ? " 4 " :
				" 6 "}
			of every set in this category for 50% off
			{categories === "Metal & Enamel Dice Set" ? " (excludes DAD552, DAD553, & Camouflage)" :
				categories === "Glass Dice Set" ? " (excludes DAD841 & DAD842)" :
					categories === "Resin Polyhedral Dice Set" ? " (excludes DAD065 & DAD066)" :
						""}
		</p>;
	} else {
		return
	}
}
function setDiscount(cart, dollarTarget) {
	let discountExclusions = getDiscountExclusions();
	if (categoryDollarPriceBreakEnabled) {
		if (cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) >= dollarTarget) {
			discountApplied = true;
			applyDiscountToCartItems(0.5);
		} else if (cart.cartItems.reduce((a, c) => a + c.retail_price * .5 * c.quantity, 0) <= dollarTarget && discountApplied) {
			discountApplied = false;
			applyDiscountToCartItems(0.6)
		}
	}

	function applyDiscountToCartItems(discountAmount) {
		cart.cartItems.forEach((item) => {
			if (!discountExclusions.includes(item.sku)) {
				item.discount = discountAmount;
			}
		});
	}
}

function getDiscountExclusions() {
	return ["DAD677", "DAD676", "DAD675", "DAD553", "DAD552", "DAD842", "DAD841", "DAD065", "DAD066", "DAD359", "DAD360"];
}

function getSubtotal(cart) {
	let subtotal = 0;
	cart.cartItems.forEach((item) => {
		subtotal += item.retail_price * item.quantity * item.discount;
	});
	return subtotal.toFixed(2);
}