import React, { Component } from 'react'


export class SigninScreen extends Component {
  render() {
    return (
      <div className='login'>
          <h1>Login</h1>
          <form action='/auth' method='post'>
              <label for="username">
                  <i className='fas fa-user'></i>
              </label>
              <input type='text' name='username' placeholder='Email' id='username' required></input>
              <label for='password'>
                  <i className='fas fa-lock'></i>
              </label>
              <input type='password' name='password' placeholder='password' id='password' required></input>
              <input type='submit' value='Login'></input>
          </form>
      </div>
    )
  }
}

export default SigninScreen