import React from "react";
import { Helmet } from "react-helmet";

function ContactUsPage(){

    return (
        <div className="container-fluid">
            <div>
                <Helmet>
                    <title>
                        Dice Habit | Contact Us
                    </title>
                </Helmet>
            </div>
            <div style={{paddingTop: "6vh"}}>
            <div className="grid-item">
                <div className="Product-Group">
                    <h4 className="Category-Header row center anchor" style={{ 
                        'background-color': '#656263'
                        }}>
                        Contact Us
                    </h4>
                    <div className="center alt-expo" style={ {
                        'padding': '5% 0',
                        'textAlign': 'center',
                        }}>
                        <span className="leading-strong">
                            <strong className="center"><i>Call or Text:</i></strong> <i>+1 (626) 203-9737</i>
                        </span>
                        <br></br>
                        <span className="leading-strong">
                        <strong className="center"><i>Email:</i></strong> <i>dicehabit@gmail.com</i>
                        </span>
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="Product-Group">
                    <h4 className="Category-Header row center anchor" style={{ 
                        'background-color': '#59c1ba' }}>
                        Our Inspiration
                    </h4>
                    <div className="center alt-expo" style={ {
                        'padding': '5% 0',
                        'textAlign': 'center',
                        }}>
                        <span className="leading-strong">
                            <strong><i>It's all about the colors</i></strong><br></br>
                            <i>
                            The natural world is a beautiful place, <br></br>
                            look up and you’ll see the richest blue, <br></br>
                            vibrant colors, dancing, all around, you <br></br>
                            red sunrise, orange berries, violet blossoms, <br></br>
                            every single hue<br></br>
                            this is mine, this is yours, this is theirs too<br></br>
                            </i>
                        </span>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default ContactUsPage;