import react from "react";

const colorMap = {
    "Metal & Enamel Dice Set": "#E75B61",
    "Dragon Scale Dice Set": "#7851a9", 
    "Hollow Dice Set": "#55b47c",
    "Sharp Edge Dice Set": "#fd64ac",
    "Gemstone Dice Set": "#34568B",
    "Glass Dice Set": "#5D1451",
    "LED Dice Set": "#22ac41",
    "Metal d100 Dice": "#ff9465",
    "Resin d100 Dice": "#509bd6",
    "(D20) Metal & Enamel Dice": "#9a999d",
    "(D6) Metal & Enamel Dice Set": "#a3836c",
    "Resin Polyhedral Dice Set": "#61c19c",
    "Acrylic Dice Set": "#ff6666",
    "Dice Counters": "#4c4d50",
    "Folding Dice Tray": "#6977b2",
    "Jumbo D20 Dice": "#4169E1",
    "Mini Dice Set": "#e21d5b",
    "Wooden Dice Set": "#a76e58",
};

export default colorMap;

