import React from "react";
import ProductShowcase from "./ProductShowcase";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import colorMap from "./ColorMap";
import CategoryMap from "./CategoryMap";

export default function ShowcaseProdFix(products, categories){
    return (
      // This will add a div that adds 30px of margin to the top of the page
      <div style={{paddingTop: "6vh"}}>
        <div className="grid-item">
          {CategoryMap.map((categories) => (
            <div key={categories} className="Product-Group">
              <h4 className="Category-Header row center anchor" style={{ 'background-color': colorMap[categories] }} id={`${categories}`}>{categories}</h4>
              <Row className="row center">
              {products.filter((products) => products.category === categories).sort().reverse().map((products) => 
              (
                  <Col sm={3} md={3} lg={3} className="">
                    <ProductShowcase
                      key={products.Product_id}
                      product={products}
                    ></ProductShowcase>
                  </Col>
                ))}
                </Row>
            </div>
        ))}
        </div>
        </div>
    );
}