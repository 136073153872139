import React from 'react'

export const AddProductScreen = () => {
  return (
    <div className='AddProduct'>
    <h1>Add Product</h1>
    <form action='/AddProduct' method='post'>
        <label for="SKU">
        </label>
        <input type='text' name='SKU' placeholder='SKU' id='SKU' required></input>
        <label for="upc_barcode">
        </label>
        <input type='text' name='upc_barcode' placeholder='UPC barcode' id='upc_barcode' required></input>
        <label for="upc_label_display">
        </label>
        <input type='text' name='upc_label_display' placeholder='Upc label description' id='upc_label_display' required></input><br></br>
        <label for="item_name">
        </label>
        <input type='text' name='item_name' placeholder='Item Name' id='item_name' required></input>
        <label for="base_cost_Unit">
        </label>
        <input type='number' name='base_cost_Unit' placeholder='Base Cost Unit' id='base_cost_Unit' required></input>
        <label for="retail_price">
        </label>
        <input type='number' name='retail_price' placeholder='retail_price' id='retail_price' required></input><br></br>
        <label for="freight_cost_import">
        </label>
        <input type='number' name='freight_cost_import' placeholder='Freight Cost Import' id='freight_cost_import' required></input>
        <label for="category">
        </label>
        <input type='text' name='category' placeholder='Category' id='category' required></input>
        <label for="realTime_quantity">
        </label>
        <input type='number' name='realTime_quantity' placeholder='Quantity' id='realTime_quantity'></input><br></br>
        <label for="weight_lbs">
        </label>
        <input type='number' name='weight_lbs' placeholder='Weight lbs' id='weight_lbs'></input>
        <label for="discount">Default Discount:
        </label>
        <input type='number' name='discount' placeholder='Discount' value={0.6} id='discount'></input>
        <label for="website_viewable">
        </label>
        <span>website_viewable:</span>
        <input type='checkbox' name='website_viewable' placeholder='website_viewable' id='website_viewable'></input><br></br>
        <label for="FK_products_users">
        </label>
        <input type='number' name='FK_products_users' placeholder='Vendor #' id='FK_products_users' required></input>
        <label for="carton_quantity">
        </label>
        <input type='number' name='carton_quantity' placeholder='Carton Quantity' id='carton_quantity' required></input>
        <label for="aux_cost">
        </label>
        <input type='number' name='aux_cost' placeholder='Auxiliary cost' id='aux_cost'></input>
        <input type='submit' value='Add Item'></input>
    </form>
</div>
  )
}
