import React from "react";
import { Helmet } from "react-helmet";

function RetailerInformationScreen(){
    return (
        <div style={{paddingTop: "6vh"}}>
        <div className="container-fluid">
            <div>
                <Helmet>
                    <title>
                        Dice Habit | Retailer Information
                    </title>
                </Helmet>
            </div>
            <div className="grid-item">
                <div className="Product-Group">
                    <h4 className="Category-Header row center anchor" style={{ 'background-color': '#f36165' }}>
                        Retailer Discount
                    </h4>
                    <div className="center alt-expo" style={ {
                        'padding': '3%', 
                        'textAlign': 'center',
                        }}>
                        <span className="payment-font">
                            <ul className="center"><strong>40% off: </strong>no minimum order quantity</ul>
                            <ul className="center"><strong>50% off: </strong>each category has it's own 50% off price break quantity</ul>
                            <span className="center" style={{'fontSize': '1.4vw'}}><i>*Please note that a few special items are 40% off only</i></span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="Product-Group">
                    <h4 className="Category-Header row center anchor" style={{ 
                        'background-color': '#75cb80' }}>
                        Shipping and Handling
                    </h4>
                    <div className="center alt-expo" style={ {
                        'padding': '3%',
                        'textAlign': 'center',
                        }}>
                        <span className="payment-font">
                            <text className="center">We do charge for shipping & handling,</text> <strong className="center">but it's very reasonable</strong>
                            <br></br><br></br>
                            <strong>US Retailers: </strong>We ship everything via UPS Ground from Los Angeles, California. <br></br>
                            <strong>International: </strong>We ship everything via US Postal Service Priority Mail.
                        </span>
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="Product-Group">
                    <h4 className="Category-Header row center anchor" style={{
                        'background-color': '#936cb2'}}>
                        Payment Information
                    </h4>
                    <div className="center alt-expo" style={ {
                        'padding': '3%', 
                        'textAlign': 'center',
                        }}>
                        <span className="payment-font">
                            We accept all major Credit Cards, PayPal, COD, or Bank Transfers
                        </span>
                    </div>
                </div>
            </div>
            <div className="grid-item">
                <div className="Product-Group">
                    <h4 className="Category-Header row center anchor" style={{
                        'background-color': '#666490'}}>
                        Why Dice Habit?
                    </h4>
                    <div className="center alt-expo" style={ { 
                        'padding': '3%',
                        'color': '#404041' 
                        }}>
                        <div style={{'padding': '3% 2'}}>
                            <text>
                            <div style={{'textAlign': 'center'}}>
                                <strong className="leading-strong"><i>Ease</i></strong>    
                            </div>
                            <i>
                            Between running weekly events and keeping up with new releases,
                            how much time do you have left to curate the perfect dice selection?<br></br>
                            <br></br>
                            We can fill your display cases with all the newest and greatest dice sets from
                            the world of premium dice without you having to do much at all. Everything
                            comes beautifully packaged and cleanly labeled; just unpack and put it inside
                            your case, easy.<br></br>
                            <br></br>
                            <br></br>
                            </i>
                            </text>
                            <text>
                            <div style={{'textAlign': 'center'}}>
                                <strong className="leading-strong"><i>Selection & Stock</i></strong>
                            </div>
                            
                            
                            <i>
                             We have one of the largest selections of Premium
                            Dice in the United States. We also keep ample stock levels of everything to
                            ensure that you can restock all of your bestsellers whenever you need,
                            especially during peak times.<br></br>
                            <br></br>
                            <br></br>
                            </i>
                            </text>
                            <text>
                            <div style={{'textAlign': 'center'}}>
                                <strong className="leading-strong"><i>Price</i></strong>
                            </div>
                            
                            <i>
                            Our prices are very good! Both our retail prices and dealer costs are
                            some of the best out there, across all categories. We do not want any of your
                            customers to shy away because the price is too high. Our goal is to offer you a
                            superb product at an affordable cost, so that our Premium Dice might
                            become a thriving category in your store.<br></br>
                            </i>
                            </text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default RetailerInformationScreen;