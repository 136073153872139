import React from 'react';
import Col from "react-bootstrap/Col";
import { Button} from 'react-bootstrap';
import colorMap from './ColorMap';
import styles from './Navigation.css';


function StickyCategories() {
    /*
    Categories are stored in a nested list with the following format:
    [Simplified Name, Database Name, Color Code]
    
    Returns a div of buttons in columns.
    */

    const categoriesSimplified = [["Metal and Enamel Dice", "Metal & Enamel Dice Set"], 
                                  ["Dragon Scale Dice", "Dragon Scale Dice Set"], 
                                  ["Hollow Dice", "Hollow Dice Set"],
                                  ["Sharp Edge Dice","Sharp Edge Dice Set"],
                                  ["Gemstone Dice", "Gemstone Dice Set"],
                                  ["Glass Dice", "Glass Dice Set"],
                                  ["LED Dice", "LED Dice Set"],
                                  ["Mini Dice", "Mini Dice Set"],
                                  ["Jumbo D20 Dice", "Jumbo D20 Dice"],
                                  ["Wooden Dice", "Wooden Dice Set"],
                                  ["Metal d100 Dice","Metal d100 Dice"], 
                                  ["Resin d100 Dice","Resin d100 Dice"],
                                  ["d20 Dice Assortment","(D20) Metal & Enamel Dice"], 
                                  ["d6 Dice Sets","(D6) Metal & Enamel Dice Set"], 
                                  ["Resin Dice","Resin Polyhedral Dice Set"], 
                                  ["Acrylic Dice", "Acrylic Dice Set"],
                                  ["Dice Counters","Dice Counters"],
                                  ["Dice Trays", "Folding Dice Tray"],
                                ];
        return (
            <div>
                {categoriesSimplified.map((cat) => (
                    <Col>
                            <Button 
                            variant="flat"
                            style={{'background-color': colorMap[cat[1]] }}
                            href={"/BulkOrder"+`#${cat[1]}`}>
                                {cat[0]}
                            </Button>
                    </Col>
                ))}
            </div>
    );
};

var maxDate = new Date("2024-05-01");
function newBubble() {
	return <text tabIndex={-1} className={"badge-new"}>New</text>;
}

export default StickyCategories;