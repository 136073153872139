// utils/ColorUtils.js

/**
 * Lightens a given color by a specified percentage.
 *
 * @param {string} hex - The hexadecimal color value to lighten.
 * @param {number} percent - The percentage by which to lighten the color.
 * @returns {string} - The lightened color in hexadecimal format.
 */
export function lightenColor(hex, percent) {
    hex = hex.replace(/^#/, '');

    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    r = Math.min(255, Math.floor(r + (255 - r) * percent / 100));
    g = Math.min(255, Math.floor(g + (255 - g) * percent / 100));
    b = Math.min(255, Math.floor(b + (255 - b) * percent / 100));

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}