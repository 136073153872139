

const CategoryMap = [
    "Metal & Enamel Dice Set",
    "Dragon Scale Dice Set",
    "Hollow Dice Set",
    "Sharp Edge Dice Set",
    "Gemstone Dice Set",
    "Glass Dice Set",
    "LED Dice Set",
    "Mini Dice Set",
    "Jumbo D20 Dice",
    "Wooden Dice Set",
    "Metal d100 Dice",
    "Resin d100 Dice",
    "(D20) Metal & Enamel Dice",
    "(D6) Metal & Enamel Dice Set",
    "Resin Polyhedral Dice Set",
    "Acrylic Dice Set",
    "Dice Counters",
    "Folding Dice Tray"];

export default CategoryMap