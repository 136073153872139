import React from "react";
import Card from "react-bootstrap/Card";

export default function Product(props) {
  const { product } = props;
  var maxDate = new Date("2024-05-01");
  return (
    <Card className="Card">
      <Card.Text className={"card-text" + (new Date(product.date_created) >=  maxDate? ' just-in': null)}>{new Date(product.date_created) >=  maxDate? "New": null}</Card.Text>
      <Card.Text 
      //show backordered if product availability_status is 0
      className={"card-text" + (product.availability_status === 0? ' backordered': null)}>{product.availability_status === 0? "Out of Stock": null}</Card.Text>
        {/* <img src={`${product.image_path}`} className="card-img-top" alt={product.item_name} */}
        <img src={"http://www.georgetownhobbies.com/NewImages/" + `${product.sku}` + ".jpg"} className="card-img-top" alt={product.item_name}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/images/DiceHabitLogoNoBorder.svg";
        }}/>
        <Card.Body className="border-0">
          <Card.Text className="card-text">{product.sku}</Card.Text>
            <Card.Text className="card-text">{product.label_display}</Card.Text>
        </Card.Body>
    </Card>
  );
}

function getDateThreeMonthsAgo(){
  var d = new Date();
  d.setMonth(d.getMonth() - 3);
  return d;
}