import React, { useEffect, useState, useContext } from "react";
import { Store } from "../Store";
import Button from "react-bootstrap/Button";
import API from "../components/API";
import "./productTableStyle.css";
import { Container } from "react-bootstrap";

function updateYourPrice(uniqueID) {
	/*
  Updates "Your cost" field on page. Except the initial TypeError on initial page load.
  - Darren
  */
	let customerQuantity;
	try {
		customerQuantity = Math.round(document.getElementById(uniqueID).value);
	} catch (e) {
		if (e instanceof TypeError) {
			return 1;
		}
	}
	return customerQuantity;
}
const api_url = API();

function ProductTable(props) {
	const { products } = props;
	const { state, dispatch: ctxDispatch } = useContext(Store);
	//const { cart } = state;
	const {
		cart: { cartItems },
	} = state;

	const addToCartHandler = async () => {
		const existItem = cartItems.find((x) => x.product_id === products.product_id);
		const quantity = existItem ? Math.round(document.getElementById(products.product_id).value) : Math.round(document.getElementById(products.product_id).value);
		if (quantity > 0) {
			ctxDispatch({
				type: "CART_ADD_ITEM",
				payload: {
					...products,
					quantity,
				},
			});
		} else if (quantity == 0) {
			ctxDispatch({
				type: "CART_REMOVE_ITEM",
				payload: {
					...products,
					quantity,
				},
			});
		} else {
			alert("Please use a positive number!");
		}
	};
	function updateCartHandler(num) {
		let temp = document.getElementById(products.product_id);
		if (temp.value <= 0 && num < 0) {
			temp.value = parseInt(1);
		} else if (temp.value <= 0 && num === 1) {
			temp.value = parseInt(0);
		}
		temp.value = parseInt(temp.value) + num;
		const tempValue = temp.value;
		const existItem = cartItems.find((x) => x.product_id === products.product_id);
		const quantity = existItem ? document.getElementById(products.product_id).value : document.getElementById(products.product_id).value;
		if (quantity > 0) {
			ctxDispatch({
				type: "CART_ADD_ITEM",
				payload: {
					...products,
					quantity,
				},
			});
		} else {
			ctxDispatch({
				type: "CART_REMOVE_ITEM",
				payload: {
					...products,
					quantity,
				},
			});
		}
	}
	var maxDate = new Date("2024-05-01");

	return (
		<tr key={products.product_id} className={`table-products-rows ${products.product_id % 2 === 0 ? 'even' : 'odd'}`}>
			<td className="table-products-rows-number" tabIndex={-1}></td>
			<td className="table-thumb-cell table-image-cell" tabIndex={-1}>
				<Container className="display-icon-container">
					<a tabIndex={-1} href={"https://www.georgetownhobbies.com/NewImages/"+`${products.sku}` + ".jpg"} target="_blank" rel="noreferrer">
						<img
							tabIndex={-1}
							className="thumb"
							src={"https://www.georgetownhobbies.com/NewImages/" + `${products.sku}` + "t.jpg"}
							alt={`${products.item_name}`}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = "/images/DiceHabitLogoNoBorder.svg";
							}}></img>
					</a>
					<div className="bubbles-container">
						{newBubble(products, maxDate)}
						{outOfStockBubble(products)}
						{excludedSkuBubble(products)}
					</div>
				</Container>
				<img tabIndex={-1} className="detailed-thumb hidden" src={"https://www.georgetownhobbies.com/NewImages/" + `${products.sku}` + ".jpg"} alt={`${products.item_name}`}></img>
			</td>
			<td>{products.sku}</td>
			<td>{products.item_name}</td>
			<td className="true-image-container" tabIndex={-1}>
				<span className="image-container" tabIndex={-1}>
					<a className="hover-text" href={"https://www.georgetownhobbies.com/NewImages/"+`${products.sku}`+".jpg"} tabIndex={-1} target="_blank" rel="noreferrer">
						Image
					</a>
				</span>
			</td>
			<td>${products.retail_price}</td>
			<td>${(products.retail_price * products.discount).toFixed(2)}</td>
			<td className="table-thumb-cell quantity">
				<Button variant="" onClick={() => updateCartHandler(-1)} tabIndex={-1} disabled={!cartItems.find((x) => x.product_id === products.product_id)}>
					<i className="fas fa-minus-circle"></i>
				</Button>
				<input
					id={products.product_id}
					className="table-products-inputs"
					disabled={(products.availability_status) === 0}
					name="quantity"
					type="number"
					pattern=" 0+\.[0-9]*[1-9][0-9]*$"
					placeholder="qty"
					step="1"
					value={cartItems.find((x) => x.product_id === products.product_id) ? cartItems.find((x) => x.product_id === products.product_id).quantity : undefined}
					min={0}
					onChange={addToCartHandler}>
					</input>
					<img className="detailed-thumb hidden" src={"https://www.georgetownhobbies.com/NewImages/" + `${products.sku}` + ".jpg"} alt={`${products.item_name}`} tabIndex={-1}></img>
				<Button 
				className="plus-circle" variant=""
				 onClick={() => updateCartHandler(1)} 
				 tabIndex={-1} 
				 disabled={(products.availability_status) === 0}>
					<i className="fas fa-plus-circle"></i>
				</Button>
			</td>
			<td>${cartItems.length === 0 ? "0.00" : (products.retail_price * products.discount * updateYourPrice(products.product_id)).toFixed(2)}</td>
		</tr>
	);
}

export default ProductTable;

function newBubble(products, maxDate) {
	return <text tabIndex={-1} className={(products.availability_status) === 0 ? "hidden" : new Date(products.date_created) >= maxDate ? "just-in-tableVersion" : null}>{new Date(products.date_created) >= maxDate ? "New" : null}</text>;
}

function outOfStockBubble(products) {
	return <text tabIndex={-1} className={(products.availability_status) === 0 ? "backorder-tableVersion" : null}>{(products.availability_status) === 0 ? "Out of Stock" : null}</text>;
}

function excludedSkuBubble(products) {
	let discountExclusions = ["DAD677", "DAD676", "DAD675", "DAD553", "DAD552", "DAD842", "DAD841", "DAD065", "DAD066", "DAD359", "DAD360"];
	if (discountExclusions.includes(products.sku)) {
	return <text className="excludedSku-tableVersion">* 40% Off *</text>;
	}
}

function getDateThreeMonthsAgo(){
	var d = new Date();
	d.setMonth(d.getMonth() - 3);
	return d;
  }