import React from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useContext } from "react";
import axios from "axios";
import API from "../components/API";
import { Store } from "../Store";
import Button from "react-bootstrap/Button";
import "./styles/CartTableStyle.css";

const api_url = API();



export default function CartTable(item){
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const {
      cart: { cartItems },
    } = state;
  
    const updateCartHandler = async (item, quantity) => {
      const { data } = await axios.get(
        `${api_url}/api/db/products/${item.product_id}`);
      ctxDispatch({
        type: "CART_ADD_ITEM",
        payload: { ...item, quantity },
      });
    };
    const removeItemHandler = (item) => {
      ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
    };

    if (item.length === 0){
        return (
            <Alert>
                Cart is empty. <Link to="/">Go Shopping</Link>
            </Alert>
        );
    }
    return (
        <tr className="cartTable"
        style={{textAlign: "center", verticalAlign: "middle", height: "2vw"}
        }>
                    <td>
                      <a href={"http://www.georgetownhobbies.com/NewImages/" + `${item.sku}` + "t.jpg"} target="_blank" rel="noreferrer">
                        <img
                        className="thumb-checkout-table-small"
                        src={"http://www.georgetownhobbies.com/NewImages/" + `${item.sku}` + "t.jpg"}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/images/DiceHabitLogoNoBorder.svg";
                        }}
                        alt="thumbnail">
                        </img>
                      </a>
                    </td>
                    <td>{item.sku}</td>
                    <td>{item.item_name}</td>
                    <td>${item.retail_price}</td>
                    <td className="unitCost">${(item.retail_price * item.discount).toFixed(2)}</td>
                    <td>
                    <Button
                          onClick={() =>
                            updateCartHandler(item, parseInt(item.quantity) - 1)
                          }
                          variant=""
                          disabled={item.quantity <= 1}
                        >
                          <i className="fas fa-minus-circle"></i>
                        </Button>
                        <span>{item.quantity}</span>{" "}
                        <Button
                          onClick={() =>
                            updateCartHandler(item, parseInt(item.quantity) + 1)
                          }
                          variant=""
                        >
                          <i className="fas fa-plus-circle"></i>
                        </Button>
                    </td>
                    <td className="lineTotal">${(item.retail_price * item.discount * item.quantity).toFixed(2)}</td>
                    <td>
                    <span 
                        onClick={()=> removeItemHandler(item)}
                        className="checkout-btn btn btn-danger"> Remove
                        </span>
                    </td>
                </tr>
    );
}