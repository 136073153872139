import React from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import { Store } from "./Store";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import CartScreen from "./screens/CartScreen";
import { AddProductScreen } from "./screens/AddProductScreen";
import SigninScreen from "./screens/SigninScreen";
import BulkOrderScreen from "./screens/BulkOrderScreen";
import RetailerInformationScreen from "./screens/RetailerInformationScreen";
import ProductPackagingScreen from "./screens/ProductPackagingScreen";
import StickyCategories from "./components/StickyCategories";
import StickyCategories_nonProductPages from "./components/StickyCategories-nonProductPages";
import ContactUsPage from "./screens/ContactDiceHabit";
import OrderSubmission from "./screens/OrderSubmitted";
import "./navBar.css";

function App() {
  const { state } = useContext(Store);
  const { cart } = state;
  const stickCats = StickyCategories();
  const stickCats_nonProductPages = StickyCategories_nonProductPages();
  return (
    <BrowserRouter forceRefresh={true}>
      <div className="d-flex flex-column site-container">
        <Helmet>
          <title>Dice Habit</title>
        </Helmet>
        <div className="Main-Navigation">
          <Navbar variant="primary" className="custom-nav">
            <Container className="flex-column">
              <LinkContainer to="/">
                <Navbar.Brand className="nav-brand">
                  <img
                    className="medium-Logo"
                    src="\images\DiceHabitLogoNoBorder.svg"
                    alt="DiceHabitLogo"
                  ></img>
                </Navbar.Brand>
              </LinkContainer>
              <NavLink to="/cart" className="cart-link">
            <img
              className="cart-img"
              src="\images\cart.png"
              alt="Cart"></img>
            {cart.cartItems.length > 0 && (
              <Badge pill bg="danger">
                {cart.cartItems.reduce((a, c) => a + parseInt(c.quantity), 0)}
              </Badge>
            )}
          </NavLink>
            </Container>
          </Navbar>
          <Nav className="lower-Navigation-row">
                <NavLink to="/" className="lower-Navigation-button">
                  Dice Showcase
                </NavLink>
                <NavLink to="/ProductPackaging" className="lower-Navigation-button">
                  Product Packaging
                </NavLink>
                <NavLink to="/RetailerInformation" className="lower-Navigation-button">
                  Retailer Information
                </NavLink>
                <NavLink to="/ContactDiceHabit" className="lower-Navigation-button">
                  Contact Us
                </NavLink>
                <NavLink to="/BulkOrder" className="lower-Navigation-button">
                  Order Now
                </NavLink>
              </Nav>
        </div>

        <div className="nav-main-container">
          <Routes>
            <Route path="/" element={<div className="sideNav sticky">{stickCats}</div>} />
            <Route path="/cart" element={<div className="sideNav sticky">{stickCats_nonProductPages}</div>} />
            <Route path="/RetailerInformation" element={<div className="sideNav sticky">{stickCats_nonProductPages}</div>} />
            <Route path="/ProductPackaging" element={<div className="sideNav sticky">{stickCats_nonProductPages}</div>} />
            <Route path="/ContactDiceHabit" element={<div className="sideNav sticky">{stickCats_nonProductPages}</div>} />
            <Route path="/BulkOrder" element={<div className="sideNav sticky">{stickCats}</div>} />
            <Route path="/OrderSubmission" element={<div className="sideNav sticky">{stickCats_nonProductPages}</div>} />
          </Routes>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/cart" element={<CartScreen />} />
            <Route path="/AddProduct" element={<AddProductScreen />} />
            <Route path="/signin" element={<SigninScreen />} />
            <Route path="/RetailerInformation" element={<RetailerInformationScreen />} />
            <Route path="/ProductPackaging" element={<ProductPackagingScreen />} />
            <Route path="/ContactDiceHabit" element={<ContactUsPage />} />
            <Route path="/BulkOrder" element={<BulkOrderScreen />} />
            <Route path="/OrderSubmission" element={<OrderSubmission />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;